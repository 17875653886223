import React, { useEffect, Suspense } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import Loader from 'rsuite/Loader';

const Home = React.lazy(() => import('./pages/Home'));
const Contact = React.lazy(() => import('./pages/Contact'));


function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: 'ease-out-cubic',
    });
  }, []);

  useDocTitle("Niwy Natury - Pracownia Zdrowia.");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/kontakt" element={<Contact />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
